import * as Yup from "yup";

export const initialValues = {
  email: "",
  password: "",
};

export const validationLoginSchema = () => Yup.object({
  email: Yup.string().required("* Required").email("* Entered incorrect email"),
  password: Yup.string().required("* Required"),
});

export const validationSendSchema = () => Yup.object({
  email: Yup.string().required("* Required").email("* Entered incorrect email"),
});
