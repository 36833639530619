import { handleLogout } from "state/logoutHelper";
import {
  fakeMenuData, fakeTypesData,
} from "../constants/fakeData";
import { mapFromFileToFormData } from "./mappers";
// eslint-disable-next-line import/no-cycle

export default class Api {
  // eslint-disable-next-line class-methods-use-this
  static baseUrl = process.env.REACT_APP_API_HOST;

  static async fetchData(path, requestOptions) {
    try {
      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });

      const statusCode = response.status;
      const data = await response.json();

      // if (statusCode === 403 || statusCode === 401) {
      //   handleLogout();
      // }

      return {
        data,
        statusCode,
      };
    } catch (e) {
      console.log("FETCH ERROR:", e);
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  static async getData(path, tokenRequired = true) {
    const myHeaders = {};

    if (tokenRequired) {
      const accessToken = path === "/refresh" ? localStorage.getItem("refresh_token") : localStorage.getItem("access_token");
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      mode: "cors",
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  static async postData(path, data, formData, tokenRequired = true) {
    const myHeaders = {};
    if (!formData) {
      myHeaders["Content-Type"] = "application/json";
    }

    if (tokenRequired) {
      const accessToken = localStorage.getItem("access_token");
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: "follow",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrerPolicy: "no-referrer",
    };

    return this.fetchData(path, requestOptions);
  }

  static async putData(path, data, formData) {
    const accessToken = localStorage.getItem("access_token");
    const myHeaders = {};
    if (!formData) {
      myHeaders["Content-Type"] = "application/json";
    }

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: "follow",
    };

    return this.fetchData(path, requestOptions);
  }

  static async deleteData(path, data) {
    const accessToken = localStorage.getItem("access_token");
    const myHeaders = {};

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(data),
    };

    return this.fetchData(path, requestOptions);
  }

  static async sendData(data) {
    return this.postData("", data);
  }

  // eslint-disable-next-line class-methods-use-this
  static async getTypes() {
    return Promise.resolve({ data: fakeTypesData, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  static async getPages(offset, limit, params) {
    const path = `/articles/?offset=${offset || 0}&limit=${limit || 10}${params || ""}`;

    return this.getData(path);
  }

  // eslint-disable-next-line class-methods-use-this
  static async deletePage(id) {
    return this.deleteData(`/articles/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  static async getPage(id) {
    return this.getData(`/articles/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  static async createPage(data) {
    return this.postData("/articles/", data);
  }

  // eslint-disable-next-line class-methods-use-this
  static async updatePage(id, data) {
    return this.putData(`/articles/${id}`, data);
  }

  // eslint-disable-next-line class-methods-use-this
  static async getLanguages() {
    return this.getData("/languages/");
  }

  static async getPagesMainInfo() {
    return this.getData("/types/");
  }

  static async getPagesMeta(offset, limit, params) {
    const path = `/meta_data/?offset=${offset || 0}&limit=${limit || 10}`;

    return this.getData(path);
  }

  static async getPageMetaData(id) {
    const path = `/meta_data/${id}`;

    return this.getData(path);
  }

  static async createPageMetaData(id, values) {
    const path = `/meta_data/${id ?? ""}`;

    return this.postData(path, values);
  }

  static async updatePageMetaData(id, values) {
    const path = `/meta_data/${id}`;

    return this.putData(path, values);
  }

  static async deletePageMetaData(id) {
    const path = `/meta_data/${id}`;

    return this.deleteData(path);
  }

  // eslint-disable-next-line class-methods-use-this
  static async login(data) {
    return this.postData("/login", data, false);
  }

  static async otpLogin(data) {
    return this.postData("/otp/login", data, false);
  }

  static async otpSend(data) {
    return this.postData("/otp/send", data, false);
  }

  // eslint-disable-next-line class-methods-use-this
  static async logout() {
    return this.getData("/logout");
  }

  // eslint-disable-next-line class-methods-use-this
  static async refresh() {
    return this.getData("/refresh");
  }

  static async createFile(data) {
    const formDataFromData = mapFromFileToFormData(data.file);

    return this.postData("/files/", formDataFromData, true);
  }

  static async getFile(id) {
    return this.getData(`/files/${id}`);
  }

  static async updateFile(id, data) {
    const formDataFromData = mapFromFileToFormData(data.file);
    return this.putData(`/files/${id}`, formDataFromData, true);
  }

  static async deleteFile(id) {
    return this.deleteData(`/files/${id}`);
  }

  static async getVacancies(offset, limit, params) {
    const path = `/vacancies/?offset=${offset || 0}&limit=${limit || 10}`;

    return this.getData(path);
  }

  static async getVacancy(id) {
    const path = `/vacancies/${id}`;

    return this.getData(path);
  }

  static async createVacancy(id, values) {
    const path = `/vacancies/${id ?? ""}`;

    return this.postData(path, values);
  }

  static async updateVacancy(id, values) {
    const path = `/vacancies/${id}`;

    return this.putData(path, values);
  }

  static async deleteVacancy(id) {
    const path = `/vacancies/${id}`;

    return this.deleteData(path);
  }

  static async getResumes(id, offset, limit, params) {
    const path = id ? `/vacancies/${id}/responses?offset=${offset || 0}&limit=${limit || 10}` : `/resumes/?offset=${offset || 0}&limit=${limit || 10}`;

    return this.getData(path);
  }
}
